import { default as _91_46_46_46slug_93iFzx9XNSoRMeta } from "/opt/build/repo/domains/content/pages/[...slug].vue?macro=true";
import { default as _400qhJBXBrSM1Meta } from "/opt/build/repo/domains/core/pages/400.vue?macro=true";
import { default as _401ItfQ97RD80Meta } from "/opt/build/repo/domains/core/pages/401.vue?macro=true";
import { default as _403HiPxJLeGtmMeta } from "/opt/build/repo/domains/core/pages/403.vue?macro=true";
import { default as _404G5FYF8c3euMeta } from "/opt/build/repo/domains/core/pages/404.vue?macro=true";
import { default as _500xtnzTA4IXTMeta } from "/opt/build/repo/domains/core/pages/500.vue?macro=true";
import { default as hilaree_45nelsonGfOCY9ExiuMeta } from "/opt/build/repo/brands/thenorthface/pages/about-us/athletes/hilaree-nelson.vue?macro=true";
import { default as historySf5m6bIiTOMeta } from "/opt/build/repo/brands/thenorthface/pages/about-us/history.vue?macro=true";
import { default as add_45billing_45addressQgO7FOoY4jMeta } from "/opt/build/repo/domains/commerce/pages/account/address/add-billing-address.vue?macro=true";
import { default as add_45shipping_45addressnECrwjt1RaMeta } from "/opt/build/repo/domains/commerce/pages/account/address/add-shipping-address.vue?macro=true";
import { default as index7pHJ5jHdKVMeta } from "/opt/build/repo/domains/commerce/pages/account/address/index.vue?macro=true";
import { default as addpYBOtZ12f1Meta } from "/opt/build/repo/domains/commerce/pages/account/credit-cards/add.vue?macro=true";
import { default as indexmudLHLDe4UMeta } from "/opt/build/repo/domains/commerce/pages/account/credit-cards/index.vue?macro=true";
import { default as gift_45cards7uHCsfDhVHMeta } from "/opt/build/repo/domains/commerce/pages/account/gift-cards.vue?macro=true";
import { default as indexc4s1RaNYYtMeta } from "/opt/build/repo/domains/commerce/pages/account/index.vue?macro=true";
import { default as loyaltyE9m8Qwcn0gMeta } from "/opt/build/repo/domains/commerce/pages/account/loyalty.vue?macro=true";
import { default as _91_46_46_46id_93aX78vf4ysSMeta } from "/opt/build/repo/domains/commerce/pages/account/order-details/[...id].vue?macro=true";
import { default as order_45historyasXzwY1JphMeta } from "/opt/build/repo/domains/commerce/pages/account/order-history.vue?macro=true";
import { default as profilegEaRvs8BcLMeta } from "/opt/build/repo/domains/commerce/pages/account/profile.vue?macro=true";
import { default as accounthlDnJ5OiYzMeta } from "/opt/build/repo/domains/commerce/pages/account.vue?macro=true";
import { default as _91slug_938pmL5oygQYMeta } from "/opt/build/repo/domains/commerce/pages/articles/[slug].vue?macro=true";
import { default as backpack_45findersCtBquqcLiMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/backpack-finder.vue?macro=true";
import { default as basecampVjjIV2ShsxMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/basecamp.vue?macro=true";
import { default as buy_45backAIJMX5ej9DMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/buy-back.vue?macro=true";
import { default as _91_46_46_46slug_93bprO6UBVz6Meta } from "/opt/build/repo/domains/commerce/pages/c/[...slug].vue?macro=true";
import { default as cartWuVmEXFYumMeta } from "/opt/build/repo/domains/commerce/pages/cart.vue?macro=true";
import { default as checkoutpVgIzhHGMpMeta } from "/opt/build/repo/domains/commerce/pages/checkout.vue?macro=true";
import { default as previewbsjXZUsg2WMeta } from "/opt/build/repo/domains/content/pages/cms/preview.vue?macro=true";
import { default as singlemJA5aUzhqfMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/single.vue?macro=true";
import { default as splittWKYwg5sChMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/split.vue?macro=true";
import { default as viewerZzlU0ZTRMuMeta } from "/opt/build/repo/domains/content/pages/cms/showcase/viewer.vue?macro=true";
import { default as earth_45dayksOLl9ofhEMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/earth-day.vue?macro=true";
import { default as events6BGWcfhGGyMeta } from "/opt/build/repo/brands/thenorthface/pages/events.vue?macro=true";
import { default as explore_45collectionTkRHvDWwDZMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/explore-collection.vue?macro=true";
import { default as _91slug_93pvQhNMuvFeMeta } from "/opt/build/repo/brands/thenorthface/pages/explore/[slug].vue?macro=true";
import { default as favoritesZ6Qjd8m6kCMeta } from "/opt/build/repo/domains/commerce/pages/favorites.vue?macro=true";
import { default as _91productId_93eUl1QYzOnNMeta } from "/opt/build/repo/domains/commerce/pages/find-in-store/[productId].vue?macro=true";
import { default as footwear_45finderrmXVwm4DvXMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/footwear-finder.vue?macro=true";
import { default as forgot_45passwordpB0K6pl4D5Meta } from "/opt/build/repo/domains/commerce/pages/forgot-password.vue?macro=true";
import { default as gift_45cardtmoMyoPxmoMeta } from "/opt/build/repo/domains/commerce/pages/gift-card.vue?macro=true";
import { default as experimentsAhxf8ibh8IMeta } from "/opt/build/repo/domains/core/pages/healthcheck/experiments.vue?macro=true";
import { default as feature_45flagsAEnkTJXV99Meta } from "/opt/build/repo/domains/core/pages/healthcheck/feature-flags.vue?macro=true";
import { default as indexYNInD0yqeIMeta } from "/opt/build/repo/domains/core/pages/healthcheck/index.vue?macro=true";
import { default as mockszaKVBZ4zkrMeta } from "/opt/build/repo/domains/core/pages/healthcheck/mocks.vue?macro=true";
import { default as static_45pagesEkYVy0JFNAMeta } from "/opt/build/repo/domains/core/pages/healthcheck/static-pages.vue?macro=true";
import { default as statusDsh9ErRLnKMeta } from "/opt/build/repo/domains/core/pages/healthcheck/status.vue?macro=true";
import { default as translationsK89azthgVZMeta } from "/opt/build/repo/domains/core/pages/healthcheck/translations.vue?macro=true";
import { default as faqnPiSPzElu1Meta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/help-section/faq.vue?macro=true";
import { default as declaration_45of_45conformitycCeF0R5ZwpMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/help-section/terms-and-conditions/declaration-of-conformity.vue?macro=true";
import { default as index3bev93goYsMeta } from "/opt/build/repo/domains/commerce/pages/help/[slug]/[child_slug]/index.vue?macro=true";
import { default as indexRABbflB1hQMeta } from "/opt/build/repo/domains/commerce/pages/help/[slug]/index.vue?macro=true";
import { default as all_45topicsVt7jfuQxwoMeta } from "/opt/build/repo/domains/commerce/pages/help/all-topics.vue?macro=true";
import { default as contact_45uswxM9cdDGLjMeta } from "/opt/build/repo/domains/commerce/pages/help/contact-us.vue?macro=true";
import { default as deliverybfL3OncuAfMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/help/delivery.vue?macro=true";
import { default as indexEeWJSz6E2lMeta } from "/opt/build/repo/domains/commerce/pages/help/index.vue?macro=true";
import { default as size_45chartsWzN5gdNMicMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/help/product-info/size-charts.vue?macro=true";
import { default as how_45to_45returnYngPPeJxqJMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/help/returns/how-to-return.vue?macro=true";
import { default as warranty4OlM68IiTRMeta } from "/opt/build/repo/brands/thenorthface/pages/help/warranty.vue?macro=true";
import { default as help60JKLo08atMeta } from "/opt/build/repo/domains/commerce/pages/help.vue?macro=true";
import { default as in_45store_45registrationw5tvRvwY8vMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/in-store-registration.vue?macro=true";
import { default as vectiv5Sr41kLQGyMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/innovation/technologies/vectiv.vue?macro=true";
import { default as ipad_45qr_45registrations4OmONx3eNMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/ipad-qr-registration.vue?macro=true";
import { default as jacket_45finder6Iw0YBX3yoMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/jacket-finder.vue?macro=true";
import { default as location_45selectorRcxhmVvYElMeta } from "/opt/build/repo/domains/commerce/pages/location-selector.vue?macro=true";
import { default as lower_45envs7iyfzqrC1tMeta } from "/opt/build/repo/domains/core/pages/lower-envs.vue?macro=true";
import { default as loyalty_45programmeqk8tIizNzYMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/loyalty-programme.vue?macro=true";
import { default as newsletter_45sign_45upfh8kPbaBO0Meta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/newsletter-sign-up.vue?macro=true";
import { default as order_45confirmation2zFGjSoOQrMeta } from "/opt/build/repo/domains/commerce/pages/order-confirmation.vue?macro=true";
import { default as order_45details2hEewW5V7XMeta } from "/opt/build/repo/domains/commerce/pages/order-details.vue?macro=true";
import { default as order_45statusSEiCEG4MyaMeta } from "/opt/build/repo/domains/commerce/pages/order-status.vue?macro=true";
import { default as base_45camp_45duffelwNzHftxV6YMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/base-camp-duffel.vue?macro=true";
import { default as denaliftGciZ1QuDMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/denali.vue?macro=true";
import { default as indexmBOlXFoFe8Meta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/index.vue?macro=true";
import { default as nuptselr5IdstYmwMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/nuptse.vue?macro=true";
import { default as our_45socksebR6aSwKpQMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/our-socks.vue?macro=true";
import { default as outletUzcDiWYUCEMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/outlet.vue?macro=true";
import { default as gift_45card_45_91slug_93aBTCyRnHqOMeta } from "/opt/build/repo/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue?macro=true";
import { default as _91_46_46_46slug_938XUmtihIQsMeta } from "/opt/build/repo/domains/commerce/pages/p/[...slug].vue?macro=true";
import { default as product_45bundlesriTI6njDuLMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/product-bundles.vue?macro=true";
import { default as repair_45servicehfspOnUaKpMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/repair-service.vue?macro=true";
import { default as reset_45passwordhnp3THUaMLMeta } from "/opt/build/repo/domains/commerce/pages/reset-password.vue?macro=true";
import { default as return_45labelNxjSTzFdk0Meta } from "/opt/build/repo/domains/commerce/pages/return-label.vue?macro=true";
import { default as _91productId_93AKFxWRSIxWMeta } from "/opt/build/repo/domains/commerce/pages/reviews/[productId].vue?macro=true";
import { default as run_45for_45the_45arcticugZ2iOCtJCMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/run-for-the-arctic.vue?macro=true";
import { default as index0fogzGIqf7Meta } from "/opt/build/repo/domains/content/pages/sandbox/cms/index.vue?macro=true";
import { default as indexOjBbltyRRZMeta } from "/opt/build/repo/domains/content/pages/sandbox/dialogs/index.vue?macro=true";
import { default as indexYytI4mQazyMeta } from "/opt/build/repo/domains/content/pages/sandbox/monetate/index.vue?macro=true";
import { default as dashboardVdaazQkT64Meta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/account/dashboard.vue?macro=true";
import { default as profile1OppCRoqrFMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/account/profile.vue?macro=true";
import { default as checkoutNnt8GaEkPWMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/checkout.vue?macro=true";
import { default as order_45confirmationQ6TY8ONuoZMeta } from "/opt/build/repo/domains/commerce/pages/sandbox/preview/order-confirmation.vue?macro=true";
import { default as articlesU210RWMfaRMeta } from "/opt/build/repo/domains/commerce/pages/search/articles.vue?macro=true";
import { default as indexKjUEhFFlYyMeta } from "/opt/build/repo/domains/commerce/pages/search/index.vue?macro=true";
import { default as searchxnc5Nq4Lb2Meta } from "/opt/build/repo/domains/commerce/pages/search.vue?macro=true";
import { default as shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/shoe-finder-retail-vectiv.vue?macro=true";
import { default as shoe_45finder_45retailkKwvf3J25OMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/shoe-finder-retail.vue?macro=true";
import { default as sign_45invOXFFa0gzPMeta } from "/opt/build/repo/domains/commerce/pages/sign-in.vue?macro=true";
import { default as size_45chart0T7nwjfX8oMeta } from "/opt/build/repo/domains/commerce/pages/size-chart.vue?macro=true";
import { default as footerAUtE5rq5gqMeta } from "/opt/build/repo/domains/commerce/pages/soci/footer.vue?macro=true";
import { default as frame382Y1ymrZaMeta } from "/opt/build/repo/domains/commerce/pages/soci/frame.vue?macro=true";
import { default as headerR4riDyN3LsMeta } from "/opt/build/repo/domains/commerce/pages/soci/header.vue?macro=true";
import { default as store_45locatortqupMGLkzCMeta } from "/opt/build/repo/domains/commerce/pages/store-locator.vue?macro=true";
import { default as storeipadsoPsMGnKdNyMeta } from "/opt/build/repo/brands/thenorthface/pages/storeipads.vue?macro=true";
import { default as student_45discountUm0kHLR0CNMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/student-discount.vue?macro=true";
import { default as summit_45series_45footwearKsek3IbNHBMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/summit-series-footwear.vue?macro=true";
import { default as summit_45seriesXvLddWPn1OMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/summit-series.vue?macro=true";
import { default as take_45back1nEsldH6OpMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/take-back.vue?macro=true";
import { default as tent_45sleepingbag_45guideShhAI4wXE4Meta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/tent-sleepingbag-guide.vue?macro=true";
import { default as triclimate_45jackets42Ey4ez9hSMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/triclimate-jackets.vue?macro=true";
import { default as write_45a_45reviewb5NDvBQXhOMeta } from "/opt/build/repo/domains/commerce/pages/write-a-review.vue?macro=true";
import { default as xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta } from "/opt/build/repo/brands/thenorthface/regions/emea/pages/xplr-pass-registration-qr-code.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93iFzx9XNSoRMeta?.name ?? "slug",
    path: _91_46_46_46slug_93iFzx9XNSoRMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93iFzx9XNSoRMeta || {},
    alias: _91_46_46_46slug_93iFzx9XNSoRMeta?.alias || [],
    redirect: _91_46_46_46slug_93iFzx9XNSoRMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _400qhJBXBrSM1Meta?.name ?? "400",
    path: _400qhJBXBrSM1Meta?.path ?? "/400",
    meta: _400qhJBXBrSM1Meta || {},
    alias: _400qhJBXBrSM1Meta?.alias || [],
    redirect: _400qhJBXBrSM1Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/400.vue").then(m => m.default || m)
  },
  {
    name: _401ItfQ97RD80Meta?.name ?? "401",
    path: _401ItfQ97RD80Meta?.path ?? "/401",
    meta: _401ItfQ97RD80Meta || {},
    alias: _401ItfQ97RD80Meta?.alias || [],
    redirect: _401ItfQ97RD80Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/401.vue").then(m => m.default || m)
  },
  {
    name: _403HiPxJLeGtmMeta?.name ?? "403",
    path: _403HiPxJLeGtmMeta?.path ?? "/403",
    meta: _403HiPxJLeGtmMeta || {},
    alias: _403HiPxJLeGtmMeta?.alias || [],
    redirect: _403HiPxJLeGtmMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/403.vue").then(m => m.default || m)
  },
  {
    name: _404G5FYF8c3euMeta?.name ?? "404",
    path: _404G5FYF8c3euMeta?.path ?? "/404",
    meta: _404G5FYF8c3euMeta || {},
    alias: _404G5FYF8c3euMeta?.alias || [],
    redirect: _404G5FYF8c3euMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500xtnzTA4IXTMeta?.name ?? "500",
    path: _500xtnzTA4IXTMeta?.path ?? "/500",
    meta: _500xtnzTA4IXTMeta || {},
    alias: _500xtnzTA4IXTMeta?.alias || [],
    redirect: _500xtnzTA4IXTMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/500.vue").then(m => m.default || m)
  },
  {
    name: hilaree_45nelsonGfOCY9ExiuMeta?.name ?? "about-us-athletes-hilaree-nelson",
    path: hilaree_45nelsonGfOCY9ExiuMeta?.path ?? "/about-us/athletes/hilaree-nelson",
    meta: hilaree_45nelsonGfOCY9ExiuMeta || {},
    alias: hilaree_45nelsonGfOCY9ExiuMeta?.alias || [],
    redirect: hilaree_45nelsonGfOCY9ExiuMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/about-us/athletes/hilaree-nelson.vue").then(m => m.default || m)
  },
  {
    name: historySf5m6bIiTOMeta?.name ?? "about-us-history",
    path: historySf5m6bIiTOMeta?.path ?? "/about-us/history",
    meta: historySf5m6bIiTOMeta || {},
    alias: historySf5m6bIiTOMeta?.alias || [],
    redirect: historySf5m6bIiTOMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/about-us/history.vue").then(m => m.default || m)
  },
  {
    name: accounthlDnJ5OiYzMeta?.name ?? undefined,
    path: accounthlDnJ5OiYzMeta?.path ?? "/account",
    meta: accounthlDnJ5OiYzMeta || {},
    alias: accounthlDnJ5OiYzMeta?.alias || [],
    redirect: accounthlDnJ5OiYzMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45billing_45addressQgO7FOoY4jMeta?.name ?? "account-address-add-billing-address",
    path: add_45billing_45addressQgO7FOoY4jMeta?.path ?? "address/add-billing-address",
    meta: add_45billing_45addressQgO7FOoY4jMeta || {},
    alias: add_45billing_45addressQgO7FOoY4jMeta?.alias || [],
    redirect: add_45billing_45addressQgO7FOoY4jMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/add-billing-address.vue").then(m => m.default || m)
  },
  {
    name: add_45shipping_45addressnECrwjt1RaMeta?.name ?? "account-address-add-shipping-address",
    path: add_45shipping_45addressnECrwjt1RaMeta?.path ?? "address/add-shipping-address",
    meta: add_45shipping_45addressnECrwjt1RaMeta || {},
    alias: add_45shipping_45addressnECrwjt1RaMeta?.alias || [],
    redirect: add_45shipping_45addressnECrwjt1RaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/add-shipping-address.vue").then(m => m.default || m)
  },
  {
    name: index7pHJ5jHdKVMeta?.name ?? "account-address",
    path: index7pHJ5jHdKVMeta?.path ?? "address",
    meta: index7pHJ5jHdKVMeta || {},
    alias: index7pHJ5jHdKVMeta?.alias || [],
    redirect: index7pHJ5jHdKVMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/address/index.vue").then(m => m.default || m)
  },
  {
    name: addpYBOtZ12f1Meta?.name ?? "account-credit-cards-add",
    path: addpYBOtZ12f1Meta?.path ?? "credit-cards/add",
    meta: addpYBOtZ12f1Meta || {},
    alias: addpYBOtZ12f1Meta?.alias || [],
    redirect: addpYBOtZ12f1Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/credit-cards/add.vue").then(m => m.default || m)
  },
  {
    name: indexmudLHLDe4UMeta?.name ?? "account-credit-cards",
    path: indexmudLHLDe4UMeta?.path ?? "credit-cards",
    meta: indexmudLHLDe4UMeta || {},
    alias: indexmudLHLDe4UMeta?.alias || [],
    redirect: indexmudLHLDe4UMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/credit-cards/index.vue").then(m => m.default || m)
  },
  {
    name: gift_45cards7uHCsfDhVHMeta?.name ?? "account-gift-cards",
    path: gift_45cards7uHCsfDhVHMeta?.path ?? "gift-cards",
    meta: gift_45cards7uHCsfDhVHMeta || {},
    alias: gift_45cards7uHCsfDhVHMeta?.alias || [],
    redirect: gift_45cards7uHCsfDhVHMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/gift-cards.vue").then(m => m.default || m)
  },
  {
    name: indexc4s1RaNYYtMeta?.name ?? "account",
    path: indexc4s1RaNYYtMeta?.path ?? "",
    meta: indexc4s1RaNYYtMeta || {},
    alias: indexc4s1RaNYYtMeta?.alias || [],
    redirect: indexc4s1RaNYYtMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: loyaltyE9m8Qwcn0gMeta?.name ?? "account-loyalty",
    path: loyaltyE9m8Qwcn0gMeta?.path ?? "loyalty",
    meta: loyaltyE9m8Qwcn0gMeta || {},
    alias: loyaltyE9m8Qwcn0gMeta?.alias || [],
    redirect: loyaltyE9m8Qwcn0gMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/loyalty.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93aX78vf4ysSMeta?.name ?? "account-order-details-id",
    path: _91_46_46_46id_93aX78vf4ysSMeta?.path ?? "order-details/:id(.*)*",
    meta: _91_46_46_46id_93aX78vf4ysSMeta || {},
    alias: _91_46_46_46id_93aX78vf4ysSMeta?.alias || [],
    redirect: _91_46_46_46id_93aX78vf4ysSMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/order-details/[...id].vue").then(m => m.default || m)
  },
  {
    name: order_45historyasXzwY1JphMeta?.name ?? "account-order-history",
    path: order_45historyasXzwY1JphMeta?.path ?? "order-history",
    meta: order_45historyasXzwY1JphMeta || {},
    alias: order_45historyasXzwY1JphMeta?.alias || [],
    redirect: order_45historyasXzwY1JphMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/order-history.vue").then(m => m.default || m)
  },
  {
    name: profilegEaRvs8BcLMeta?.name ?? "account-profile",
    path: profilegEaRvs8BcLMeta?.path ?? "profile",
    meta: profilegEaRvs8BcLMeta || {},
    alias: profilegEaRvs8BcLMeta?.alias || [],
    redirect: profilegEaRvs8BcLMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_938pmL5oygQYMeta?.name ?? "articles-slug",
    path: _91slug_938pmL5oygQYMeta?.path ?? "/articles/:slug()",
    meta: _91slug_938pmL5oygQYMeta || {},
    alias: _91slug_938pmL5oygQYMeta?.alias || [],
    redirect: _91slug_938pmL5oygQYMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: backpack_45findersCtBquqcLiMeta?.name ?? "backpack-finder",
    path: backpack_45findersCtBquqcLiMeta?.path ?? "/backpack-finder",
    meta: backpack_45findersCtBquqcLiMeta || {},
    alias: backpack_45findersCtBquqcLiMeta?.alias || [],
    redirect: backpack_45findersCtBquqcLiMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/backpack-finder.vue").then(m => m.default || m)
  },
  {
    name: basecampVjjIV2ShsxMeta?.name ?? "basecamp",
    path: basecampVjjIV2ShsxMeta?.path ?? "/basecamp",
    meta: basecampVjjIV2ShsxMeta || {},
    alias: basecampVjjIV2ShsxMeta?.alias || [],
    redirect: basecampVjjIV2ShsxMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/basecamp.vue").then(m => m.default || m)
  },
  {
    name: buy_45backAIJMX5ej9DMeta?.name ?? "buy-back",
    path: buy_45backAIJMX5ej9DMeta?.path ?? "/buy-back",
    meta: buy_45backAIJMX5ej9DMeta || {},
    alias: buy_45backAIJMX5ej9DMeta?.alias || [],
    redirect: buy_45backAIJMX5ej9DMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/buy-back.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bprO6UBVz6Meta?.name ?? "c-slug",
    path: _91_46_46_46slug_93bprO6UBVz6Meta?.path ?? "/c/:slug(.*)*",
    meta: _91_46_46_46slug_93bprO6UBVz6Meta || {},
    alias: _91_46_46_46slug_93bprO6UBVz6Meta?.alias || [],
    redirect: _91_46_46_46slug_93bprO6UBVz6Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cartWuVmEXFYumMeta?.name ?? "cart",
    path: cartWuVmEXFYumMeta?.path ?? "/cart",
    meta: cartWuVmEXFYumMeta || {},
    alias: cartWuVmEXFYumMeta?.alias || [],
    redirect: cartWuVmEXFYumMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutpVgIzhHGMpMeta?.name ?? "checkout",
    path: checkoutpVgIzhHGMpMeta?.path ?? "/checkout",
    meta: checkoutpVgIzhHGMpMeta || {},
    alias: checkoutpVgIzhHGMpMeta?.alias || [],
    redirect: checkoutpVgIzhHGMpMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: previewbsjXZUsg2WMeta?.name ?? "cms-preview",
    path: previewbsjXZUsg2WMeta?.path ?? "/cms/preview",
    meta: previewbsjXZUsg2WMeta || {},
    alias: previewbsjXZUsg2WMeta?.alias || [],
    redirect: previewbsjXZUsg2WMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/preview.vue").then(m => m.default || m)
  },
  {
    name: singlemJA5aUzhqfMeta?.name ?? "cms-showcase-single",
    path: singlemJA5aUzhqfMeta?.path ?? "/cms/showcase/single",
    meta: singlemJA5aUzhqfMeta || {},
    alias: singlemJA5aUzhqfMeta?.alias || [],
    redirect: singlemJA5aUzhqfMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/single.vue").then(m => m.default || m)
  },
  {
    name: splittWKYwg5sChMeta?.name ?? "cms-showcase-split",
    path: splittWKYwg5sChMeta?.path ?? "/cms/showcase/split",
    meta: splittWKYwg5sChMeta || {},
    alias: splittWKYwg5sChMeta?.alias || [],
    redirect: splittWKYwg5sChMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/split.vue").then(m => m.default || m)
  },
  {
    name: viewerZzlU0ZTRMuMeta?.name ?? "cms-showcase-viewer",
    path: viewerZzlU0ZTRMuMeta?.path ?? "/cms/showcase/viewer",
    meta: viewerZzlU0ZTRMuMeta || {},
    alias: viewerZzlU0ZTRMuMeta?.alias || [],
    redirect: viewerZzlU0ZTRMuMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/cms/showcase/viewer.vue").then(m => m.default || m)
  },
  {
    name: earth_45dayksOLl9ofhEMeta?.name ?? "earth-day",
    path: earth_45dayksOLl9ofhEMeta?.path ?? "/earth-day",
    meta: earth_45dayksOLl9ofhEMeta || {},
    alias: earth_45dayksOLl9ofhEMeta?.alias || [],
    redirect: earth_45dayksOLl9ofhEMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/earth-day.vue").then(m => m.default || m)
  },
  {
    name: events6BGWcfhGGyMeta?.name ?? "events",
    path: events6BGWcfhGGyMeta?.path ?? "/events",
    meta: events6BGWcfhGGyMeta || {},
    alias: events6BGWcfhGGyMeta?.alias || [],
    redirect: events6BGWcfhGGyMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/events.vue").then(m => m.default || m)
  },
  {
    name: explore_45collectionTkRHvDWwDZMeta?.name ?? "explore-collection",
    path: explore_45collectionTkRHvDWwDZMeta?.path ?? "/explore-collection",
    meta: explore_45collectionTkRHvDWwDZMeta || {},
    alias: explore_45collectionTkRHvDWwDZMeta?.alias || [],
    redirect: explore_45collectionTkRHvDWwDZMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/explore-collection.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pvQhNMuvFeMeta?.name ?? "explore-slug",
    path: _91slug_93pvQhNMuvFeMeta?.path ?? "/explore/:slug()",
    meta: _91slug_93pvQhNMuvFeMeta || {},
    alias: _91slug_93pvQhNMuvFeMeta?.alias || [],
    redirect: _91slug_93pvQhNMuvFeMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/explore/[slug].vue").then(m => m.default || m)
  },
  {
    name: favoritesZ6Qjd8m6kCMeta?.name ?? "favorites",
    path: favoritesZ6Qjd8m6kCMeta?.path ?? "/favorites",
    meta: favoritesZ6Qjd8m6kCMeta || {},
    alias: favoritesZ6Qjd8m6kCMeta?.alias || [],
    redirect: favoritesZ6Qjd8m6kCMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93eUl1QYzOnNMeta?.name ?? "find-in-store-productId",
    path: _91productId_93eUl1QYzOnNMeta?.path ?? "/find-in-store/:productId()",
    meta: _91productId_93eUl1QYzOnNMeta || {},
    alias: _91productId_93eUl1QYzOnNMeta?.alias || [],
    redirect: _91productId_93eUl1QYzOnNMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/find-in-store/[productId].vue").then(m => m.default || m)
  },
  {
    name: footwear_45finderrmXVwm4DvXMeta?.name ?? "footwear-finder",
    path: footwear_45finderrmXVwm4DvXMeta?.path ?? "/footwear-finder",
    meta: footwear_45finderrmXVwm4DvXMeta || {},
    alias: footwear_45finderrmXVwm4DvXMeta?.alias || [],
    redirect: footwear_45finderrmXVwm4DvXMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/footwear-finder.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordpB0K6pl4D5Meta?.name ?? "forgot-password",
    path: forgot_45passwordpB0K6pl4D5Meta?.path ?? "/forgot-password",
    meta: forgot_45passwordpB0K6pl4D5Meta || {},
    alias: forgot_45passwordpB0K6pl4D5Meta?.alias || [],
    redirect: forgot_45passwordpB0K6pl4D5Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: gift_45cardtmoMyoPxmoMeta?.name ?? "gift-card",
    path: gift_45cardtmoMyoPxmoMeta?.path ?? "/gift-card",
    meta: gift_45cardtmoMyoPxmoMeta || {},
    alias: gift_45cardtmoMyoPxmoMeta?.alias || [],
    redirect: gift_45cardtmoMyoPxmoMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/gift-card.vue").then(m => m.default || m)
  },
  {
    name: experimentsAhxf8ibh8IMeta?.name ?? "healthcheck-experiments",
    path: experimentsAhxf8ibh8IMeta?.path ?? "/healthcheck/experiments",
    meta: experimentsAhxf8ibh8IMeta || {},
    alias: experimentsAhxf8ibh8IMeta?.alias || [],
    redirect: experimentsAhxf8ibh8IMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/experiments.vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsAEnkTJXV99Meta?.name ?? "healthcheck-feature-flags",
    path: feature_45flagsAEnkTJXV99Meta?.path ?? "/healthcheck/feature-flags",
    meta: feature_45flagsAEnkTJXV99Meta || {},
    alias: feature_45flagsAEnkTJXV99Meta?.alias || [],
    redirect: feature_45flagsAEnkTJXV99Meta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: indexYNInD0yqeIMeta?.name ?? "healthcheck",
    path: indexYNInD0yqeIMeta?.path ?? "/healthcheck",
    meta: indexYNInD0yqeIMeta || {},
    alias: indexYNInD0yqeIMeta?.alias || [],
    redirect: indexYNInD0yqeIMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/index.vue").then(m => m.default || m)
  },
  {
    name: mockszaKVBZ4zkrMeta?.name ?? "healthcheck-mocks",
    path: mockszaKVBZ4zkrMeta?.path ?? "/healthcheck/mocks",
    meta: mockszaKVBZ4zkrMeta || {},
    alias: mockszaKVBZ4zkrMeta?.alias || [],
    redirect: mockszaKVBZ4zkrMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/mocks.vue").then(m => m.default || m)
  },
  {
    name: static_45pagesEkYVy0JFNAMeta?.name ?? "healthcheck-static-pages",
    path: static_45pagesEkYVy0JFNAMeta?.path ?? "/healthcheck/static-pages",
    meta: static_45pagesEkYVy0JFNAMeta || {},
    alias: static_45pagesEkYVy0JFNAMeta?.alias || [],
    redirect: static_45pagesEkYVy0JFNAMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/static-pages.vue").then(m => m.default || m)
  },
  {
    name: statusDsh9ErRLnKMeta?.name ?? "healthcheck-status",
    path: statusDsh9ErRLnKMeta?.path ?? "/healthcheck/status",
    meta: statusDsh9ErRLnKMeta || {},
    alias: statusDsh9ErRLnKMeta?.alias || [],
    redirect: statusDsh9ErRLnKMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/status.vue").then(m => m.default || m)
  },
  {
    name: translationsK89azthgVZMeta?.name ?? "healthcheck-translations",
    path: translationsK89azthgVZMeta?.path ?? "/healthcheck/translations",
    meta: translationsK89azthgVZMeta || {},
    alias: translationsK89azthgVZMeta?.alias || [],
    redirect: translationsK89azthgVZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/healthcheck/translations.vue").then(m => m.default || m)
  },
  {
    name: faqnPiSPzElu1Meta?.name ?? "help-section-faq",
    path: faqnPiSPzElu1Meta?.path ?? "/help-section/faq",
    meta: faqnPiSPzElu1Meta || {},
    alias: faqnPiSPzElu1Meta?.alias || [],
    redirect: faqnPiSPzElu1Meta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/help-section/faq.vue").then(m => m.default || m)
  },
  {
    name: declaration_45of_45conformitycCeF0R5ZwpMeta?.name ?? "help-section-terms-and-conditions-declaration-of-conformity",
    path: declaration_45of_45conformitycCeF0R5ZwpMeta?.path ?? "/help-section/terms-and-conditions/declaration-of-conformity",
    meta: declaration_45of_45conformitycCeF0R5ZwpMeta || {},
    alias: declaration_45of_45conformitycCeF0R5ZwpMeta?.alias || [],
    redirect: declaration_45of_45conformitycCeF0R5ZwpMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/help-section/terms-and-conditions/declaration-of-conformity.vue").then(m => m.default || m)
  },
  {
    name: help60JKLo08atMeta?.name ?? undefined,
    path: help60JKLo08atMeta?.path ?? "/help",
    meta: help60JKLo08atMeta || {},
    alias: help60JKLo08atMeta?.alias || [],
    redirect: help60JKLo08atMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: index3bev93goYsMeta?.name ?? "help-slug-child_slug",
    path: index3bev93goYsMeta?.path ?? ":slug()/:child_slug()",
    meta: index3bev93goYsMeta || {},
    alias: index3bev93goYsMeta?.alias || [],
    redirect: index3bev93goYsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/[slug]/[child_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRABbflB1hQMeta?.name ?? "help-slug",
    path: indexRABbflB1hQMeta?.path ?? ":slug()",
    meta: indexRABbflB1hQMeta || {},
    alias: indexRABbflB1hQMeta?.alias || [],
    redirect: indexRABbflB1hQMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: all_45topicsVt7jfuQxwoMeta?.name ?? "help-all-topics",
    path: all_45topicsVt7jfuQxwoMeta?.path ?? "all-topics",
    meta: all_45topicsVt7jfuQxwoMeta || {},
    alias: all_45topicsVt7jfuQxwoMeta?.alias || [],
    redirect: all_45topicsVt7jfuQxwoMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/all-topics.vue").then(m => m.default || m)
  },
  {
    name: contact_45uswxM9cdDGLjMeta?.name ?? "help-contact-us",
    path: contact_45uswxM9cdDGLjMeta?.path ?? "contact-us",
    meta: contact_45uswxM9cdDGLjMeta || {},
    alias: contact_45uswxM9cdDGLjMeta?.alias || [],
    redirect: contact_45uswxM9cdDGLjMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/contact-us.vue").then(m => m.default || m)
  },
  {
    name: deliverybfL3OncuAfMeta?.name ?? "help-delivery",
    path: deliverybfL3OncuAfMeta?.path ?? "delivery",
    meta: deliverybfL3OncuAfMeta || {},
    alias: deliverybfL3OncuAfMeta?.alias || [],
    redirect: deliverybfL3OncuAfMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/help/delivery.vue").then(m => m.default || m)
  },
  {
    name: indexEeWJSz6E2lMeta?.name ?? "help",
    path: indexEeWJSz6E2lMeta?.path ?? "",
    meta: indexEeWJSz6E2lMeta || {},
    alias: indexEeWJSz6E2lMeta?.alias || [],
    redirect: indexEeWJSz6E2lMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: size_45chartsWzN5gdNMicMeta?.name ?? "help-product-info-size-charts",
    path: size_45chartsWzN5gdNMicMeta?.path ?? "product-info/size-charts",
    meta: size_45chartsWzN5gdNMicMeta || {},
    alias: size_45chartsWzN5gdNMicMeta?.alias || [],
    redirect: size_45chartsWzN5gdNMicMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/help/product-info/size-charts.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45returnYngPPeJxqJMeta?.name ?? "help-returns-how-to-return",
    path: how_45to_45returnYngPPeJxqJMeta?.path ?? "returns/how-to-return",
    meta: how_45to_45returnYngPPeJxqJMeta || {},
    alias: how_45to_45returnYngPPeJxqJMeta?.alias || [],
    redirect: how_45to_45returnYngPPeJxqJMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/help/returns/how-to-return.vue").then(m => m.default || m)
  },
  {
    name: warranty4OlM68IiTRMeta?.name ?? "help-warranty",
    path: warranty4OlM68IiTRMeta?.path ?? "warranty",
    meta: warranty4OlM68IiTRMeta || {},
    alias: warranty4OlM68IiTRMeta?.alias || [],
    redirect: warranty4OlM68IiTRMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/help/warranty.vue").then(m => m.default || m)
  }
]
  },
  {
    name: in_45store_45registrationw5tvRvwY8vMeta?.name ?? "in-store-registration",
    path: in_45store_45registrationw5tvRvwY8vMeta?.path ?? "/in-store-registration",
    meta: in_45store_45registrationw5tvRvwY8vMeta || {},
    alias: in_45store_45registrationw5tvRvwY8vMeta?.alias || [],
    redirect: in_45store_45registrationw5tvRvwY8vMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/in-store-registration.vue").then(m => m.default || m)
  },
  {
    name: vectiv5Sr41kLQGyMeta?.name ?? "innovation-technologies-vectiv",
    path: vectiv5Sr41kLQGyMeta?.path ?? "/innovation/technologies/vectiv",
    meta: vectiv5Sr41kLQGyMeta || {},
    alias: vectiv5Sr41kLQGyMeta?.alias || [],
    redirect: vectiv5Sr41kLQGyMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/innovation/technologies/vectiv.vue").then(m => m.default || m)
  },
  {
    name: ipad_45qr_45registrations4OmONx3eNMeta?.name ?? "ipad-qr-registration",
    path: ipad_45qr_45registrations4OmONx3eNMeta?.path ?? "/ipad-qr-registration",
    meta: ipad_45qr_45registrations4OmONx3eNMeta || {},
    alias: ipad_45qr_45registrations4OmONx3eNMeta?.alias || [],
    redirect: ipad_45qr_45registrations4OmONx3eNMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/ipad-qr-registration.vue").then(m => m.default || m)
  },
  {
    name: jacket_45finder6Iw0YBX3yoMeta?.name ?? "jacket-finder",
    path: jacket_45finder6Iw0YBX3yoMeta?.path ?? "/jacket-finder",
    meta: jacket_45finder6Iw0YBX3yoMeta || {},
    alias: jacket_45finder6Iw0YBX3yoMeta?.alias || [],
    redirect: jacket_45finder6Iw0YBX3yoMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/jacket-finder.vue").then(m => m.default || m)
  },
  {
    name: location_45selectorRcxhmVvYElMeta?.name ?? "location-selector",
    path: location_45selectorRcxhmVvYElMeta?.path ?? "/location-selector",
    meta: location_45selectorRcxhmVvYElMeta || {},
    alias: location_45selectorRcxhmVvYElMeta?.alias || [],
    redirect: location_45selectorRcxhmVvYElMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/location-selector.vue").then(m => m.default || m)
  },
  {
    name: lower_45envs7iyfzqrC1tMeta?.name ?? "lower-envs",
    path: lower_45envs7iyfzqrC1tMeta?.path ?? "/lower-envs",
    meta: lower_45envs7iyfzqrC1tMeta || {},
    alias: lower_45envs7iyfzqrC1tMeta?.alias || [],
    redirect: lower_45envs7iyfzqrC1tMeta?.redirect,
    component: () => import("/opt/build/repo/domains/core/pages/lower-envs.vue").then(m => m.default || m)
  },
  {
    name: loyalty_45programmeqk8tIizNzYMeta?.name ?? "loyalty-programme",
    path: loyalty_45programmeqk8tIizNzYMeta?.path ?? "/loyalty-programme",
    meta: loyalty_45programmeqk8tIizNzYMeta || {},
    alias: loyalty_45programmeqk8tIizNzYMeta?.alias || [],
    redirect: loyalty_45programmeqk8tIizNzYMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/loyalty-programme.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45sign_45upfh8kPbaBO0Meta?.name ?? "newsletter-sign-up",
    path: newsletter_45sign_45upfh8kPbaBO0Meta?.path ?? "/newsletter-sign-up",
    meta: newsletter_45sign_45upfh8kPbaBO0Meta || {},
    alias: newsletter_45sign_45upfh8kPbaBO0Meta?.alias || [],
    redirect: newsletter_45sign_45upfh8kPbaBO0Meta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/newsletter-sign-up.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmation2zFGjSoOQrMeta?.name ?? "order-confirmation",
    path: order_45confirmation2zFGjSoOQrMeta?.path ?? "/order-confirmation",
    meta: order_45confirmation2zFGjSoOQrMeta || {},
    alias: order_45confirmation2zFGjSoOQrMeta?.alias || [],
    redirect: order_45confirmation2zFGjSoOQrMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: order_45details2hEewW5V7XMeta?.name ?? "order-details",
    path: order_45details2hEewW5V7XMeta?.path ?? "/order-details",
    meta: order_45details2hEewW5V7XMeta || {},
    alias: order_45details2hEewW5V7XMeta?.alias || [],
    redirect: order_45details2hEewW5V7XMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-details.vue").then(m => m.default || m)
  },
  {
    name: order_45statusSEiCEG4MyaMeta?.name ?? "order-status",
    path: order_45statusSEiCEG4MyaMeta?.path ?? "/order-status",
    meta: order_45statusSEiCEG4MyaMeta || {},
    alias: order_45statusSEiCEG4MyaMeta?.alias || [],
    redirect: order_45statusSEiCEG4MyaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/order-status.vue").then(m => m.default || m)
  },
  {
    name: base_45camp_45duffelwNzHftxV6YMeta?.name ?? "our-icons-base-camp-duffel",
    path: base_45camp_45duffelwNzHftxV6YMeta?.path ?? "/our-icons/base-camp-duffel",
    meta: base_45camp_45duffelwNzHftxV6YMeta || {},
    alias: base_45camp_45duffelwNzHftxV6YMeta?.alias || [],
    redirect: base_45camp_45duffelwNzHftxV6YMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/base-camp-duffel.vue").then(m => m.default || m)
  },
  {
    name: denaliftGciZ1QuDMeta?.name ?? "our-icons-denali",
    path: denaliftGciZ1QuDMeta?.path ?? "/our-icons/denali",
    meta: denaliftGciZ1QuDMeta || {},
    alias: denaliftGciZ1QuDMeta?.alias || [],
    redirect: denaliftGciZ1QuDMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/denali.vue").then(m => m.default || m)
  },
  {
    name: indexmBOlXFoFe8Meta?.name ?? "our-icons",
    path: indexmBOlXFoFe8Meta?.path ?? "/our-icons",
    meta: indexmBOlXFoFe8Meta || {},
    alias: indexmBOlXFoFe8Meta?.alias || [],
    redirect: indexmBOlXFoFe8Meta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/index.vue").then(m => m.default || m)
  },
  {
    name: nuptselr5IdstYmwMeta?.name ?? "our-icons-nuptse",
    path: nuptselr5IdstYmwMeta?.path ?? "/our-icons/nuptse",
    meta: nuptselr5IdstYmwMeta || {},
    alias: nuptselr5IdstYmwMeta?.alias || [],
    redirect: nuptselr5IdstYmwMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/our-icons/nuptse.vue").then(m => m.default || m)
  },
  {
    name: our_45socksebR6aSwKpQMeta?.name ?? "our-socks",
    path: our_45socksebR6aSwKpQMeta?.path ?? "/our-socks",
    meta: our_45socksebR6aSwKpQMeta || {},
    alias: our_45socksebR6aSwKpQMeta?.alias || [],
    redirect: our_45socksebR6aSwKpQMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/our-socks.vue").then(m => m.default || m)
  },
  {
    name: outletUzcDiWYUCEMeta?.name ?? "outlet",
    path: outletUzcDiWYUCEMeta?.path ?? "/outlet",
    meta: outletUzcDiWYUCEMeta || {},
    alias: outletUzcDiWYUCEMeta?.alias || [],
    redirect: outletUzcDiWYUCEMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/outlet.vue").then(m => m.default || m)
  },
  {
    name: gift_45card_45_91slug_93aBTCyRnHqOMeta?.name ?? "p-catalog-gift-card-slug",
    path: gift_45card_45_91slug_93aBTCyRnHqOMeta?.path ?? "/p/:catalog(.*)*/gift-card-:slug()",
    meta: gift_45card_45_91slug_93aBTCyRnHqOMeta || {},
    alias: gift_45card_45_91slug_93aBTCyRnHqOMeta?.alias || [],
    redirect: gift_45card_45_91slug_93aBTCyRnHqOMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/p/[...catalog]/gift-card-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_938XUmtihIQsMeta?.name ?? "p-slug",
    path: _91_46_46_46slug_938XUmtihIQsMeta?.path ?? "/p/:slug(.*)*",
    meta: _91_46_46_46slug_938XUmtihIQsMeta || {},
    alias: _91_46_46_46slug_938XUmtihIQsMeta?.alias || [],
    redirect: _91_46_46_46slug_938XUmtihIQsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: product_45bundlesriTI6njDuLMeta?.name ?? "product-bundles",
    path: product_45bundlesriTI6njDuLMeta?.path ?? "/product-bundles",
    meta: product_45bundlesriTI6njDuLMeta || {},
    alias: product_45bundlesriTI6njDuLMeta?.alias || [],
    redirect: product_45bundlesriTI6njDuLMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/product-bundles.vue").then(m => m.default || m)
  },
  {
    name: repair_45servicehfspOnUaKpMeta?.name ?? "repair-service",
    path: repair_45servicehfspOnUaKpMeta?.path ?? "/repair-service",
    meta: repair_45servicehfspOnUaKpMeta || {},
    alias: repair_45servicehfspOnUaKpMeta?.alias || [],
    redirect: repair_45servicehfspOnUaKpMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/repair-service.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordhnp3THUaMLMeta?.name ?? "reset-password",
    path: reset_45passwordhnp3THUaMLMeta?.path ?? "/reset-password",
    meta: reset_45passwordhnp3THUaMLMeta || {},
    alias: reset_45passwordhnp3THUaMLMeta?.alias || [],
    redirect: reset_45passwordhnp3THUaMLMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: return_45labelNxjSTzFdk0Meta?.name ?? "return-label",
    path: return_45labelNxjSTzFdk0Meta?.path ?? "/return-label",
    meta: return_45labelNxjSTzFdk0Meta || {},
    alias: return_45labelNxjSTzFdk0Meta?.alias || [],
    redirect: return_45labelNxjSTzFdk0Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/return-label.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93AKFxWRSIxWMeta?.name ?? "reviews-productId",
    path: _91productId_93AKFxWRSIxWMeta?.path ?? "/reviews/:productId()",
    meta: _91productId_93AKFxWRSIxWMeta || {},
    alias: _91productId_93AKFxWRSIxWMeta?.alias || [],
    redirect: _91productId_93AKFxWRSIxWMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/reviews/[productId].vue").then(m => m.default || m)
  },
  {
    name: run_45for_45the_45arcticugZ2iOCtJCMeta?.name ?? "run-for-the-arctic",
    path: run_45for_45the_45arcticugZ2iOCtJCMeta?.path ?? "/run-for-the-arctic",
    meta: run_45for_45the_45arcticugZ2iOCtJCMeta || {},
    alias: run_45for_45the_45arcticugZ2iOCtJCMeta?.alias || [],
    redirect: run_45for_45the_45arcticugZ2iOCtJCMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/run-for-the-arctic.vue").then(m => m.default || m)
  },
  {
    name: index0fogzGIqf7Meta?.name ?? "sandbox-cms",
    path: index0fogzGIqf7Meta?.path ?? "/sandbox/cms",
    meta: index0fogzGIqf7Meta || {},
    alias: index0fogzGIqf7Meta?.alias || [],
    redirect: index0fogzGIqf7Meta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/cms/index.vue").then(m => m.default || m)
  },
  {
    name: indexOjBbltyRRZMeta?.name ?? "sandbox-dialogs",
    path: indexOjBbltyRRZMeta?.path ?? "/sandbox/dialogs",
    meta: indexOjBbltyRRZMeta || {},
    alias: indexOjBbltyRRZMeta?.alias || [],
    redirect: indexOjBbltyRRZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/dialogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexYytI4mQazyMeta?.name ?? "sandbox-monetate",
    path: indexYytI4mQazyMeta?.path ?? "/sandbox/monetate",
    meta: indexYytI4mQazyMeta || {},
    alias: indexYytI4mQazyMeta?.alias || [],
    redirect: indexYytI4mQazyMeta?.redirect,
    component: () => import("/opt/build/repo/domains/content/pages/sandbox/monetate/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardVdaazQkT64Meta?.name ?? "sandbox-preview-account-dashboard",
    path: dashboardVdaazQkT64Meta?.path ?? "/sandbox/preview/account/dashboard",
    meta: dashboardVdaazQkT64Meta || {},
    alias: dashboardVdaazQkT64Meta?.alias || [],
    redirect: dashboardVdaazQkT64Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/account/dashboard.vue").then(m => m.default || m)
  },
  {
    name: profile1OppCRoqrFMeta?.name ?? "sandbox-preview-account-profile",
    path: profile1OppCRoqrFMeta?.path ?? "/sandbox/preview/account/profile",
    meta: profile1OppCRoqrFMeta || {},
    alias: profile1OppCRoqrFMeta?.alias || [],
    redirect: profile1OppCRoqrFMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/account/profile.vue").then(m => m.default || m)
  },
  {
    name: checkoutNnt8GaEkPWMeta?.name ?? "sandbox-preview-checkout",
    path: checkoutNnt8GaEkPWMeta?.path ?? "/sandbox/preview/checkout",
    meta: checkoutNnt8GaEkPWMeta || {},
    alias: checkoutNnt8GaEkPWMeta?.alias || [],
    redirect: checkoutNnt8GaEkPWMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/checkout.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationQ6TY8ONuoZMeta?.name ?? "sandbox-preview-order-confirmation",
    path: order_45confirmationQ6TY8ONuoZMeta?.path ?? "/sandbox/preview/order-confirmation",
    meta: order_45confirmationQ6TY8ONuoZMeta || {},
    alias: order_45confirmationQ6TY8ONuoZMeta?.alias || [],
    redirect: order_45confirmationQ6TY8ONuoZMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sandbox/preview/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: searchxnc5Nq4Lb2Meta?.name ?? "search-parent",
    path: searchxnc5Nq4Lb2Meta?.path ?? "/search",
    meta: searchxnc5Nq4Lb2Meta || {},
    alias: searchxnc5Nq4Lb2Meta?.alias || [],
    redirect: searchxnc5Nq4Lb2Meta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: articlesU210RWMfaRMeta?.name ?? "search-articles",
    path: articlesU210RWMfaRMeta?.path ?? "articles",
    meta: articlesU210RWMfaRMeta || {},
    alias: articlesU210RWMfaRMeta?.alias || [],
    redirect: articlesU210RWMfaRMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search/articles.vue").then(m => m.default || m)
  },
  {
    name: indexKjUEhFFlYyMeta?.name ?? "search",
    path: indexKjUEhFFlYyMeta?.path ?? "",
    meta: indexKjUEhFFlYyMeta || {},
    alias: indexKjUEhFFlYyMeta?.alias || [],
    redirect: indexKjUEhFFlYyMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta?.name ?? "shoe-finder-retail-vectiv",
    path: shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta?.path ?? "/shoe-finder-retail-vectiv",
    meta: shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta || {},
    alias: shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta?.alias || [],
    redirect: shoe_45finder_45retail_45vectiv5XcjCCbXcqMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/shoe-finder-retail-vectiv.vue").then(m => m.default || m)
  },
  {
    name: shoe_45finder_45retailkKwvf3J25OMeta?.name ?? "shoe-finder-retail",
    path: shoe_45finder_45retailkKwvf3J25OMeta?.path ?? "/shoe-finder-retail",
    meta: shoe_45finder_45retailkKwvf3J25OMeta || {},
    alias: shoe_45finder_45retailkKwvf3J25OMeta?.alias || [],
    redirect: shoe_45finder_45retailkKwvf3J25OMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/shoe-finder-retail.vue").then(m => m.default || m)
  },
  {
    name: sign_45invOXFFa0gzPMeta?.name ?? "sign-in",
    path: sign_45invOXFFa0gzPMeta?.path ?? "/sign-in",
    meta: sign_45invOXFFa0gzPMeta || {},
    alias: sign_45invOXFFa0gzPMeta?.alias || [],
    redirect: sign_45invOXFFa0gzPMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: size_45chart0T7nwjfX8oMeta?.name ?? "size-chart",
    path: size_45chart0T7nwjfX8oMeta?.path ?? "/size-chart",
    meta: size_45chart0T7nwjfX8oMeta || {},
    alias: size_45chart0T7nwjfX8oMeta?.alias || [],
    redirect: size_45chart0T7nwjfX8oMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/size-chart.vue").then(m => m.default || m)
  },
  {
    name: footerAUtE5rq5gqMeta?.name ?? "soci-footer",
    path: footerAUtE5rq5gqMeta?.path ?? "/soci/footer",
    meta: footerAUtE5rq5gqMeta || {},
    alias: footerAUtE5rq5gqMeta?.alias || [],
    redirect: footerAUtE5rq5gqMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/footer.vue").then(m => m.default || m)
  },
  {
    name: frame382Y1ymrZaMeta?.name ?? "soci-frame",
    path: frame382Y1ymrZaMeta?.path ?? "/soci/frame",
    meta: frame382Y1ymrZaMeta || {},
    alias: frame382Y1ymrZaMeta?.alias || [],
    redirect: frame382Y1ymrZaMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/frame.vue").then(m => m.default || m)
  },
  {
    name: headerR4riDyN3LsMeta?.name ?? "soci-header",
    path: headerR4riDyN3LsMeta?.path ?? "/soci/header",
    meta: headerR4riDyN3LsMeta || {},
    alias: headerR4riDyN3LsMeta?.alias || [],
    redirect: headerR4riDyN3LsMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/soci/header.vue").then(m => m.default || m)
  },
  {
    name: store_45locatortqupMGLkzCMeta?.name ?? "store-locator",
    path: store_45locatortqupMGLkzCMeta?.path ?? "/store-locator",
    meta: store_45locatortqupMGLkzCMeta || {},
    alias: store_45locatortqupMGLkzCMeta?.alias || [],
    redirect: store_45locatortqupMGLkzCMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/store-locator.vue").then(m => m.default || m)
  },
  {
    name: storeipadsoPsMGnKdNyMeta?.name ?? "storeipads",
    path: storeipadsoPsMGnKdNyMeta?.path ?? "/storeipads",
    meta: storeipadsoPsMGnKdNyMeta || {},
    alias: storeipadsoPsMGnKdNyMeta?.alias || [],
    redirect: storeipadsoPsMGnKdNyMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/pages/storeipads.vue").then(m => m.default || m)
  },
  {
    name: student_45discountUm0kHLR0CNMeta?.name ?? "student-discount",
    path: student_45discountUm0kHLR0CNMeta?.path ?? "/student-discount",
    meta: student_45discountUm0kHLR0CNMeta || {},
    alias: student_45discountUm0kHLR0CNMeta?.alias || [],
    redirect: student_45discountUm0kHLR0CNMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/student-discount.vue").then(m => m.default || m)
  },
  {
    name: summit_45series_45footwearKsek3IbNHBMeta?.name ?? "summit-series-footwear",
    path: summit_45series_45footwearKsek3IbNHBMeta?.path ?? "/summit-series-footwear",
    meta: summit_45series_45footwearKsek3IbNHBMeta || {},
    alias: summit_45series_45footwearKsek3IbNHBMeta?.alias || [],
    redirect: summit_45series_45footwearKsek3IbNHBMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/summit-series-footwear.vue").then(m => m.default || m)
  },
  {
    name: summit_45seriesXvLddWPn1OMeta?.name ?? "summit-series",
    path: summit_45seriesXvLddWPn1OMeta?.path ?? "/summit-series",
    meta: summit_45seriesXvLddWPn1OMeta || {},
    alias: summit_45seriesXvLddWPn1OMeta?.alias || [],
    redirect: summit_45seriesXvLddWPn1OMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/summit-series.vue").then(m => m.default || m)
  },
  {
    name: take_45back1nEsldH6OpMeta?.name ?? "take-back",
    path: take_45back1nEsldH6OpMeta?.path ?? "/take-back",
    meta: take_45back1nEsldH6OpMeta || {},
    alias: take_45back1nEsldH6OpMeta?.alias || [],
    redirect: take_45back1nEsldH6OpMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/take-back.vue").then(m => m.default || m)
  },
  {
    name: tent_45sleepingbag_45guideShhAI4wXE4Meta?.name ?? "tent-sleepingbag-guide",
    path: tent_45sleepingbag_45guideShhAI4wXE4Meta?.path ?? "/tent-sleepingbag-guide",
    meta: tent_45sleepingbag_45guideShhAI4wXE4Meta || {},
    alias: tent_45sleepingbag_45guideShhAI4wXE4Meta?.alias || [],
    redirect: tent_45sleepingbag_45guideShhAI4wXE4Meta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/tent-sleepingbag-guide.vue").then(m => m.default || m)
  },
  {
    name: triclimate_45jackets42Ey4ez9hSMeta?.name ?? "triclimate-jackets",
    path: triclimate_45jackets42Ey4ez9hSMeta?.path ?? "/triclimate-jackets",
    meta: triclimate_45jackets42Ey4ez9hSMeta || {},
    alias: triclimate_45jackets42Ey4ez9hSMeta?.alias || [],
    redirect: triclimate_45jackets42Ey4ez9hSMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/triclimate-jackets.vue").then(m => m.default || m)
  },
  {
    name: write_45a_45reviewb5NDvBQXhOMeta?.name ?? "write-a-review",
    path: write_45a_45reviewb5NDvBQXhOMeta?.path ?? "/write-a-review",
    meta: write_45a_45reviewb5NDvBQXhOMeta || {},
    alias: write_45a_45reviewb5NDvBQXhOMeta?.alias || [],
    redirect: write_45a_45reviewb5NDvBQXhOMeta?.redirect,
    component: () => import("/opt/build/repo/domains/commerce/pages/write-a-review.vue").then(m => m.default || m)
  },
  {
    name: xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta?.name ?? "xplr-pass-registration-qr-code",
    path: xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta?.path ?? "/xplr-pass-registration-qr-code",
    meta: xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta || {},
    alias: xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta?.alias || [],
    redirect: xplr_45pass_45registration_45qr_45codeqIPUHvEhZSMeta?.redirect,
    component: () => import("/opt/build/repo/brands/thenorthface/regions/emea/pages/xplr-pass-registration-qr-code.vue").then(m => m.default || m)
  }
]